import CityItem from '../CityItem';
import classes from './CityList.module.scss';

export default function CityList({ citiesData, setSearchData }: any): JSX.Element {
  let id = 0;
  return (
    <ul className={classes.list}>
      {citiesData.map((el: any) => {
        id += 1;
        return <CityItem cityData={el} setSearchData={setSearchData} key={id} />;
      })}
    </ul>
  );
}
