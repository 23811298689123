const apiBase = 'https://api.weatherapi.com/v1';

const { REACT_APP_API_KEY } = process.env;

const getCurrentWeather = async (id: number) => {
  let response = await fetch(`${apiBase}/current.json?key=${REACT_APP_API_KEY}&q=id:${id}&aqi=no&lang=en`);
  const data = await response.json();
  return data;
};

const getAutoComplete = async (query: string) => {
  const response = await fetch(`${apiBase}/search.json?key=${REACT_APP_API_KEY}&q=${query}&lang=en`);
  const data = await response.json();
  return data;
};

const api = {
  getAutoComplete,
  getCurrentWeather,
};

export default api;
