import CitySearch from '../components/CitySearch';
import CityWeather from '../components/CityWeather';
import { CityIdProvider } from '../contexts/CityId';
import classes from './App.module.scss';

function App() {
  return (
    <div className={classes.wrapper}>
      <CityIdProvider>
        <CitySearch />
        <CityWeather />
      </CityIdProvider>
    </div>
  );
}

export default App;
