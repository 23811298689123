import { useContext, useEffect, useState } from 'react';
import { CityIdContext } from '../../contexts/CityId';
import api from '../../services';
import { getIconId } from '../../utils/conditionIcon';
import classes from './CityWeather.module.scss';

export function CityWeather(): JSX.Element {
  const { cityId } = useContext(CityIdContext);
  const weatherCond = {
    location: {
      name: '--',
      region: 'Moscow City',
      country: '--',
    },
    current: {
      temp_c: '--',
      is_day: 1,
      condition: {
        text: '--',
        icon: '',
        code: 1183,
      },
      wind_kph: 0,
      wind_degree: 220,
      wind_dir: '',
      pressure_mb: 1000.0,
      pressure_in: 29.53,
      precip_mm: 0.05,
      precip_in: 0.0,
      humidity: '--',
      cloud: 100,
      feelslike_c: -1.2,
      feelslike_f: 29.9,
      vis_km: 10.0,
      vis_miles: 6.0,
      uv: '--',
      gust_mph: 15.5,
      gust_kph: 24.9,
    },
  };

  const [currentWeather, setCurrentWeather] = useState(weatherCond);

  const imageLink = `//cdn.weatherapi.com/weather/128x128/${currentWeather.current.is_day ? 'day' : 'night'}/${getIconId(
    currentWeather.current.condition.code
  )}.png`;

  useEffect(() => {
    if (cityId !== 0) {
      api.getCurrentWeather(cityId).then((res) => setCurrentWeather(res));
    }
  }, [cityId]);

  if (cityId !== 0) {
    return (
      <div className={classes.main}>
        <span className={classes.cityName}>
          {currentWeather.location.name}, {currentWeather.location.country}
        </span>
        <div className={classes.wrap}>
          <div className={classes.mainConditions}>
            <figure className={classes.figure}>
              <img src={imageLink} className={classes.image} alt={currentWeather.current.condition.text} />
              <figcaption style={{ textAlign: 'center' }}>{currentWeather.current.condition.text}</figcaption>
            </figure>
            <span className={classes.temperature}>{currentWeather.current.temp_c}°</span>
          </div>
          <div className={classes.secondaryConditions}>
            <span>
              Wind: {(currentWeather.current.wind_kph * 0.27777).toFixed(0)} m/s {currentWeather.current.wind_dir}
            </span>
            <span>Humidity: {currentWeather.current.humidity}%</span>
            <span>UV index: {currentWeather.current.uv}</span>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}
