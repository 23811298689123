import { useCallback, useState } from 'react';
import api from '../../services';
import debounce from '../../utils/debounce';
import CityList from '../CityList';
import classes from './CitySearch.module.scss';

export function CitySearch(): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchData, setSearchData] = useState<any>([]);
  // eslint-disable-next-line
  const debouncedQuery = useCallback(
    debounce(
      (value: string) =>
        api.getAutoComplete(value).then((res) => {
          setSearchData(res);
        }),
      400
    ),
    []
  );

  const onChangeInput = (value: string) => {
    setSearchValue(value);
    if (value) {
      debouncedQuery(value);
    } else {
      setSearchData([])
    }
  };

  return (
    <header className={classes.header}>
      <div className={classes.searchWrap}>
        <input
          className={classes.searchLine}
          placeholder="City name..."
          value={searchValue}
          onChange={(e) => {
            onChangeInput(e.target.value);
          }}
        />
      </div>
      {searchData.length > 0 ? (
        <CityList citiesData={searchData} setSearchData={setSearchData} className={classes.list} />
      ) : null}
    </header>
  );
}
