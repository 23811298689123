import { useContext } from 'react';
import { CityIdContext } from '../../contexts/CityId';
import classes from './CityItem.module.scss';

export default function CityItem({ cityData, setSearchData }: any): JSX.Element {
  const { name, region, country, id } = cityData;
  const { setCityId } = useContext(CityIdContext);

  return (
    <li className={classes.item} key={id}>
      <button
        className={classes.primary}
        onClick={() => {
          setCityId(id);
          setSearchData([]);
        }}
      >
        {name},{' '}
        <span className={classes.secondary}>
          {region}, {country}
        </span>
      </button>
    </li>
  );
}
