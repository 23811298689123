import { useMemo, useState } from 'react';
import { CityIdContext } from './context';

type Props = {
  children: React.ReactNode;
};

export const CityIdProvider: React.FC<Props> = ({ children }) => {
  const [cityId, setCityId] = useState<number>(0);

  const value = useMemo(
    () => ({
      cityId,
      setCityId,
    }),
    [cityId]
  );

  return <CityIdContext.Provider value={value}>{children}</CityIdContext.Provider>;
};
